export const leadershipWeight = [
  {
    name: "willingness",
    label: "领导意愿",
  },
  {
    name: "humanized",
    label: "以人为本",
  },
  {
    name: "inputs",
    label: "敬业投入",
  },
  {
    name: "energy",
    label: "领导精力",
  },
  {
    name: "skill",
    label: "领导技能",
  },
  {
    name: "collaboration",
    label: "协作",
  },
  {
    name: "communication",
    label: "有效沟通",
  },
];
