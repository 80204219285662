<template>
  <!--  <div>领导力权重管理</div>-->
  <section class="app-container">
    <a-form
      :model="modelRef"
      :rules="rulesRef"
      :labelCol="{ span: 2 }"
      :wrapperCol="{ span: 10 }"
    >
      <a-form-item
        v-for="{ name, label } in config"
        :key="name"
        :label="label"
        :name="name"
        v-bind="validateInfos[name]"
      >
        <a-input-number
          :default-value="modelRef[name]"
          :min="10"
          :max="40"
          :step="10"
          :formatter="(value) => value ? `${value}%` : ''"
          :parser="(value) => value.replace('%', '')"
          v-model:value="modelRef[name]"
        />
      </a-form-item>
      <a-form-item :wrapper-col="{ span: 10, offset: 2 }">
        <a-button type="primary" @click.prevent="onSubmit">保存</a-button>
        <a-button style="margin-left: 10px" @click="onCancel">取消</a-button>
      </a-form-item>
    </a-form>
  </section>
</template>

<script>
import { defineComponent, onMounted, reactive, toRaw } from "vue";
import { message } from "ant-design-vue";
import { useGlobalPropertyHook } from "@/hooks/common.js";
import { Form } from "ant-design-vue";
import { leadershipWeight } from "@/config/leadership";
const useForm = Form.useForm;
export default defineComponent({
  name: "index",
  setup() {
    let data = {};
    let modelRef = reactive({
      willingness: '',
      humanized: '',
      communication: '',
      collaboration: '',
      skill: '',
      energy: '',
      inputs: '',
    });
    const inputNumberRule = [
      {
        required: true,
        trigger: "change",
        validator: async (rule, value) => {
          if (isNaN(value) || value < 10 || value > 40 || value % 10 !== 0) {
            return Promise.reject("请输入10~40之间的数字,且是10的整数倍");
          }
        },
      },
    ];
    const rulesRef = reactive({
      willingness: inputNumberRule,
      humanized: inputNumberRule,
      communication: inputNumberRule,
      collaboration: inputNumberRule,
      skill: inputNumberRule,
      energy: inputNumberRule,
      inputs: inputNumberRule,
    });
    const { validate, validateInfos } = useForm(modelRef, rulesRef);
    const { $api } = useGlobalPropertyHook();

    const getWeight = () => {
      $api.getWeight().then((res) => {
        data = res.data[0];
        if (data) {
          for (let key in toRaw(modelRef)) {
            modelRef[key] = data[key];
          }
        }
      });
    };
    const onSubmit = async () => {
      await validate();
      const total = Object.values(toRaw(modelRef)).reduce(
        (pre, cur) => pre + cur
      );
      if (total !== 100) return message.error("各项之和必须为100%");
      $api
        .updateWeight({
          id: data ? data.id : "",
          ...toRaw(modelRef),
        })
        .then((res) => {
          data = res.data;
          message.success("保存成功！");
        })
    };

    const onCancel = () => {
      if (data) {
        for (let key in toRaw(modelRef)) {
          modelRef[key] = data[key];
        }
      } else {
        Object.assign(modelRef, {
          willingness: '',
          humanized: '',
          communication: '',
          collaboration: '',
          skill: '',
          energy: '',
          inputs: '',
        });
      }
    };

    onMounted(() => {
      getWeight();
    });

    return {
      config: leadershipWeight,
      modelRef,
      rulesRef,
      validateInfos,
      onSubmit,
      onCancel,
    };
  },
});
</script>

<style scoped lang="less">
.app-container {
  height: 100%;
  padding: 20px;
  background: #ffffff;
  border-radius: 8px;
}
</style>
